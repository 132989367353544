import { createContext, useContext, ReactNode, useState, useEffect } from 'react';

interface RouterContextType {
    currentPath: string;
    setPath: (path: string) => void;
    navigate: (path: string) => void;
}

export const RouterContext = createContext<RouterContextType>({
    currentPath: window.location.pathname,
    setPath: () => { },
    navigate: () => { },
});

export const RouterProvider = ({ children }: { children: ReactNode }) => {
    const [currentPath, setCurrentPath] = useState(window.location.pathname);

    useEffect(() => {
        const handleLocation = () => {
            if (window.location.pathname !== '/sitemap.xml') {
                setCurrentPath(window.location.pathname);
            }
        };

        window.addEventListener('popstate', handleLocation);

        handleLocation();

        return () => window.removeEventListener('popstate', handleLocation);
    }, []);

    const setPath = (path: string) => {
        if (path !== '/sitemap.xml') {
            setCurrentPath(path);
        }
    };

    const navigate = (path: string) => {
        if (path !== '/sitemap.xml') {
            window.history.pushState({}, '', path);
            setCurrentPath(path);
        }
    };

    return (
        <RouterContext.Provider value={{ currentPath, setPath, navigate }}>
            {children}
        </RouterContext.Provider>
    );
};

export const useRouter = () => useContext(RouterContext);