import React, { useEffect, useState } from 'react';
import { useRouter } from '../context/RouterContext';

const Hero = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const { navigate } = useRouter();

  const shuffleArray = (array: string[]) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const [slides] = useState(() =>
    shuffleArray([
      '/images/gallery/1.jpg',
      '/images/gallery/2.jpg',
      '/images/gallery/3.jpg',
      '/images/gallery/4.jpg',
      '/images/gallery/5.jpg',
      '/images/gallery/6.jpg',
      '/images/gallery/7.jpg',
      '/images/gallery/8.jpg',
      '/images/gallery/9.jpg',
      '/images/gallery/10.jpg',
      '/images/gallery/11.jpg',
      '/images/gallery/12.jpg',
      '/images/gallery/13.jpg'
    ])
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
        setIsTransitioning(false);
      }, 500);
    }, 5000);

    return () => clearInterval(timer);
  }, [slides.length]);

  return (
    <div className="relative h-screen">
      {/* Background image section */}
      <div
        className={`absolute inset-0 bg-cover bg-center transition-all duration-500 ease-in-out ${isTransitioning ? 'opacity-0' : 'opacity-100'
          }`}
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${slides[currentSlide]})`,
          zIndex: isTransitioning ? 1 : 2,
        }}
      />

      {/* Content section - now separate from background */}
      <section className="absolute inset-0 flex items-center justify-center text-center text-white px-8" style={{ zIndex: 3 }}>
        <div className="animate-[fadeInUp_0.8s_ease-out]">
          <img src="/images/logo_grey.png" alt="NV-GROUP OY Logo" className="mx-auto mb-6 h-48 invert hover:animate-spin" />
          <h1 className="text-5xl md:text-6xl mb-4 font-bold">NV-GROUP OY</h1>
          <p className="text-xl md:text-2xl mb-8">
            Rakennamme ja remontoimme aina sinun tarpeisiisi sopivan kokonaisuuden
          </p>
          <a href="/#" onClick={(e) => { e.preventDefault(); navigate('/yhteydenotto') }} className="inline-block px-8 py-4 bg-secondary hover:bg-secondary-dark text-white no-underline rounded font-semibold transition-colors">
            OTA YHTEYTTÄ
          </a>
        </div>
        <div className="absolute bottom-4 flex gap-2">
          {slides.map((_, index) => (
            <button
              key={index}
              className={`w-3 h-3 rounded-full transition-all duration-300 ${currentSlide === index ? 'bg-white scale-125' : 'bg-white/50'
                }`}
              onClick={() => {
                setIsTransitioning(true);
                setTimeout(() => {
                  setCurrentSlide(index);
                  setIsTransitioning(false);
                }, 500);
              }}
            />
          ))}
        </div>
      </section>
      <div className="absolute inset-0 bg-black" style={{ zIndex: 0 }}></div>
    </div>
  );
};

export default Hero;