import React, { useEffect } from 'react';

declare global {
    interface Window {
        __opCalcAccount: any;
        __opCalcOpts: any;
    }
}

const OPCalculator: React.FC = () => {
    useEffect(() => {
        // Load OP Calculator script
        const script = document.createElement('script');
        script.src = 'js/op-calc-widget.js';
        script.async = true;
        document.body.appendChild(script);

        // Initialize calculator configuration
        window.__opCalcAccount = window.__opCalcAccount ? window.__opCalcAccount : { "mode": "kertaluotto-uusi", "key": "nvgroup" };
        window.__opCalcOpts = window.__opCalcOpts && Object.keys(window.__opCalcOpts).length ? window.__opCalcOpts : [];
        window.__opCalcOpts["floating"] = {
            "linkCta": "https://eficode.pohjola-finance.fi/kertaluotto-uusi/nvgroup",
            "linkMore": "",
            "heading": "Rahoituksen määrä",
            "colorPrimary": "#ff6a10",
            "colorSecondary": "#ffffff",
            "textOpenBtn": "Maksa erissä",
            "percentage": [0.012, 0.017],
            "enableInputChanges": true,
            "showCta": true,
            "showInput": true,
            "type": "floating",
            "initAmount": 5000,
            "showInfo": {
                "realInterest": true,
                "interestMargin": true,
                "paybackTime": true,
                "costEstimate": true
            }
        };

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div id="op-calculator"></div>
    );
};

export default OPCalculator;
