/* eslint-disable react/jsx-no-target-blank */
const RentCottagePage = () => {
    return (
        <div className="font-montserrat text-gray-800">
            <section className="py-20 px-8 bg-white">
                <div className="max-w-7xl mx-auto">
                    <h1 className="text-4xl font-bold mb-4 mt-8 text-center tracking-wider">Vuokramökki</h1>
                    <p className="text-gray-600 text-center mb-12">Viihtyisä hirsimökki Repoveden kansallispuiston läheisyydessä</p>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
                        <div className="aspect-video rounded-lg overflow-hidden shadow-lg">
                            <img
                                src="images/cottage/cottage_1.jpg"
                                alt="Mökki ulkoa"
                                className="w-full h-full object-cover"
                            />
                        </div>
                        <div className="aspect-video rounded-lg overflow-hidden shadow-lg">
                            <img
                                src="images/cottage/cottage_2.jpg"
                                alt="Mökki sisältä"
                                className="w-full h-full object-cover"
                            />
                        </div>
                    </div>

                    <div className="mb-12">
                        <div className="aspect-video rounded-lg overflow-hidden shadow-lg">
                            <iframe
                                className="w-full h-full"
                                src="https://www.youtube.com/embed/jOSLF1XSpcQ"
                                title="Mökkiesittely"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>

                    <div className="bg-primary p-8 rounded-lg shadow-sm text-white">
                        <h2 className="text-2xl font-bold mb-4">Tietoa mökistä</h2>
                        <p className="mb-4">
                            Upea hirsimökki sijaitsee kauniilla paikalla Särkilammen rannalla, ainoana mökkinä alueella. Mökki tarjoaa täydellisen pakopaikan luonnon rauhassa lähellä Repoveden kansallispuistoa. Hyvät sienestys- ja marjastusmahdollisuudet löytyvät suoraan pihapiiristä. Hinta alkaen 70€/vrk.
                        </p>
                        <p className="mb-4">
                            <a href="https://www.huvila.net/cgi-bin/pager2.cgi?huvila=2491" target="_blank" className="text-white underline">Varaa täältä tai soita meille.</a>
                        </p>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <h3 className="font-bold mb-2">Varustelu</h3>
                                <ul className="list-disc list-inside">
                                    <li>Aurinkosähköjärjestelmä (aggregaatti varavirtana)</li>
                                    <li>Puulämmitteinen sauna (7-10 hlö)</li>
                                    <li>Suihku ja lämmin vesi</li>
                                    <li>Jääkaappi ja kylmäkellari</li>
                                    <li>Puuhella ja ulkogrilli</li>
                                    <li>BIO-ulkotoiletti</li>
                                </ul>
                            </div>
                            <div>
                                <h3 className="font-bold mb-2">Tiedot</h3>
                                <ul className="list-disc list-inside">
                                    <li>75 m², 1h + avok</li>
                                    <li>6 vuodepaikkaa</li>
                                    <li>Rakennettu 2014</li>
                                    <li>80m rantaviivaa</li>
                                    <li>Liinavaatteet 15€/setti</li>
                                    <li>Kotieläimet kielletty</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default RentCottagePage