import React from 'react';

const Services = () => {
    return (
        <section className="py-20 px-8 bg-gradient-to-b from-blue-50 to-blue-100 relative">
            <div className="absolute inset-x-0 bottom-0">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="w-full">
                    <path fill="#bfdbfe" fillOpacity="0.7" d="M0,96L48,112C96,128,192,160,288,160C384,160,480,128,576,122.7C672,117,768,139,864,154.7C960,171,1056,181,1152,165.3C1248,149,1344,107,1392,85.3L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
                    <path fill="#60a5fa" fillOpacity="0.5" d="M0,192L48,181.3C96,171,192,149,288,154.7C384,160,480,192,576,197.3C672,203,768,181,864,165.3C960,149,1056,139,1152,144C1248,149,1344,171,1392,181.3L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
                </svg>
            </div>
            <div className="max-w-7xl mx-auto relative">
                <h2 className="text-4xl font-bold text-center mb-4">Palvelut</h2>
                <p className="text-gray-600 text-center mb-12 max-w-2xl mx-auto">Tarjoamme kattavat rakennuspalvelut ammattitaidolla ja kokemuksella</p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 group">
                        <div className="bg-secondary/10 w-16 h-16 rounded-full flex items-center justify-center mb-6 mx-auto">
                            <i className="fas fa-home text-3xl text-secondary group-hover:animate-spin"></i>
                        </div>
                        <h3 className="text-2xl font-bold mb-4 text-gray-800 text-center">Sisäremontit</h3>
                        <p className="text-gray-600 leading-relaxed text-center">Toteutamme laadukkaat sisäremontit suunnittelusta toteutukseen. Huomioimme asiakkaan toiveet ja tarpeet jokaisessa projektissa.</p>
                    </div>
                    <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 group">
                        <div className="bg-secondary/10 w-16 h-16 rounded-full flex items-center justify-center mb-6 mx-auto">
                            <i className="fas fa-tools text-3xl text-secondary group-hover:animate-spin"></i>
                        </div>
                        <h3 className="text-2xl font-bold mb-4 text-gray-800 text-center">Ulkoremontit</h3>
                        <p className="text-gray-600 leading-relaxed text-center">Julkisivuremontit ja ulkotilojen kunnostukset ammattitaidolla. Varmistamme kestävät ja tyylikkäät ratkaisut.</p>
                    </div>
                    <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 group">
                        <div className="bg-secondary/10 w-16 h-16 rounded-full flex items-center justify-center mb-6 mx-auto">
                            <i className="fas fa-pencil-ruler text-3xl text-secondary group-hover:animate-spin"></i>
                        </div>
                        <h3 className="text-2xl font-bold mb-4 text-gray-800 text-center">Suunnittelu</h3>
                        <p className="text-gray-600 leading-relaxed text-center">Autamme suunnittelemaan projektisi alusta loppuun. Tarjoamme asiantuntevaa näkemystä ja innovatiivisia ratkaisuja.</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Services;