import { useState, useEffect } from 'react';

const GalleryPage = () => {
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    const images: string[] = [
        '/images/gallery/1.jpg',
        '/images/gallery/2.jpg',
        '/images/gallery/3.jpg',
        '/images/gallery/4.jpg',
        '/images/gallery/5.jpg',
        '/images/gallery/6.jpg',
        '/images/gallery/7.jpg',
        '/images/gallery/8.jpg',
        '/images/gallery/9.jpg',
        '/images/gallery/10.jpg',
        '/images/gallery/11.jpg',
        '/images/gallery/12.jpg',
        '/images/gallery/13.jpg',
    ];

    const handleImageClick = (imageSrc: string) => {
        setSelectedImage(imageSrc);
        setCurrentIndex(images.indexOf(imageSrc));
    };

    const handleClose = () => {
        setSelectedImage(null);
    };

    const handlePrevious = (e?: React.MouseEvent) => {
        e?.stopPropagation();
        setCurrentIndex((prev) => {
            const newIndex = prev - 1 < 0 ? images.length - 1 : prev - 1;
            setSelectedImage(images[newIndex]);
            return newIndex;
        });
    };

    const handleNext = (e?: React.MouseEvent) => {
        e?.stopPropagation();
        setCurrentIndex((prev) => {
            const newIndex = prev + 1 >= images.length ? 0 : prev + 1;
            setSelectedImage(images[newIndex]);
            return newIndex;
        });
    };

    useEffect(() => {
        const handleKeyPress = (e: KeyboardEvent) => {
            if (!selectedImage) return;

            if (e.key === 'ArrowLeft') {
                handlePrevious();
            } else if (e.key === 'ArrowRight') {
                handleNext();
            }
        };

        window.addEventListener('keydown', handleKeyPress);
        return () => window.removeEventListener('keydown', handleKeyPress);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedImage]);

    return (
        <div className="min-h-screen w-full flex items-center justify-center md:mt-16">
            <div className="container p-8 flex flex-col items-center">
                <div className="grid grid-cols-3 gap-4 max-w-[1200px] leading-[0]">
                    {images.map((image: string, index: number) => (
                        <div key={index}
                            onClick={() => handleImageClick(image)}
                            className="leading-[0] aspect-square overflow-hidden"
                        >
                            <img
                                src={image}
                                alt={`${index + 1}`}
                                loading="lazy"
                                className="cursor-pointer w-full h-full object-cover block rounded-md"
                            />
                        </div>
                    ))}
                </div>
            </div>

            {selectedImage && (
                <div
                    className="fixed inset-0 bg-black/70 flex items-center justify-center z-[1000]"
                    onClick={handleClose}
                >
                    <button
                        onClick={handlePrevious}
                        className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white text-4xl p-4 hover:bg-black/30 rounded-full"
                    >
                        ←
                    </button>
                    <img
                        src={selectedImage}
                        alt="Enlarged view"
                        className="max-h-[90vh] max-w-[90vw] object-contain bg-white shadow-lg"
                        onClick={(e) => e.stopPropagation()}
                    />
                    <button
                        onClick={handleNext}
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white text-4xl p-4 hover:bg-black/30 rounded-full"
                    >
                        →
                    </button>
                </div>
            )}
        </div>
    );
};

export default GalleryPage;