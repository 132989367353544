import React, { useState } from 'react';
import { useRouter } from '../context/RouterContext';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { navigate } = useRouter();

  const handleNavigation = (e: React.MouseEvent<HTMLAnchorElement>, path: string) => {
    e.preventDefault();
    navigate(path);
    setIsMenuOpen(false);
  };

  return (
    <header className="bg-primary fixed w-full top-0 z-50">
      <nav className="max-w-7xl mx-auto flex justify-between items-center px-8 py-4">
        <a href="/" onClick={(e) => handleNavigation(e, '/')} className="text-white text-2xl font-bold no-underline">NV-GROUP OY</a>
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="lg:hidden text-white text-2xl p-2"
        >
          <i className="fas fa-bars"></i>
        </button>
        <div className={`${isMenuOpen ? '' : 'hidden'} lg:flex gap-8 absolute lg:relative top-full left-0 w-full lg:w-auto bg-primary lg:bg-transparent p-4 lg:p-0 text-center`}>
          <a href="/#" onClick={(e) => handleNavigation(e, '/')} className="text-white font-semibold no-underline hover:text-secondary transition-colors block lg:inline-block py-2 lg:py-0 w-full lg:w-auto">ETUSIVU</a>
          <a href="/#" onClick={(e) => handleNavigation(e, '/rahoitus')} className="text-white font-semibold no-underline hover:text-secondary transition-colors block lg:inline-block py-2 lg:py-0 w-full lg:w-auto">RAHOITUS</a>
          <a href="/#" onClick={(e) => handleNavigation(e, '/galleria')} className="text-white font-semibold no-underline hover:text-secondary transition-colors block lg:inline-block py-2 lg:py-0 w-full lg:w-auto">GALLERIA</a>
          <a href="/#" onClick={(e) => handleNavigation(e, '/yhteydenotto')} className="text-white font-semibold no-underline hover:text-secondary transition-colors block lg:inline-block py-2 lg:py-0 w-full lg:w-auto">YHTEYDENOTTO</a>
          <a href="/#" onClick={(e) => handleNavigation(e, '/vuokraus')} className="text-white font-semibold no-underline hover:text-secondary transition-colors block lg:inline-block py-2 lg:py-0 w-full lg:w-auto">VUOKRAA MÖKKI</a>
        </div>
      </nav>
    </header>
  );
};

export default Header;