const LoanPage = () => {
    return (
        <div className="font-montserrat text-gray-800 relative">
            <section className="py-24 px-8 bg-gradient-to-b from-orange-200 to-orange-500 relative">
                <div className="max-w-7xl mx-auto">
                    <h1 className="text-5xl font-bold mb-6 mt-8 text-center tracking-wider bg-clip-text text-transparent bg-gradient-to-r from-gray-800 to-gray-600">OP:n Rahoitus</h1>
                    <p className="text-gray-600 text-lg text-center mb-16 max-w-3xl mx-auto leading-relaxed">Hoida hankintasi vaivatta OP:n rahoituksella. Laske itsellesi sopiva kuukausierä laskurilla ja täytä hakemus helposti.</p>

                    <div className="max-w-4xl mx-auto mb-16 bg-white rounded-2xl shadow-lg p-10">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 text-left mb-12">
                            <ul className="space-y-4">
                                <li className="flex items-start">
                                    <span className="mr-3 text-blue-600 text-lg">•</span>
                                    <span className="text-lg">Rahoitus 2 500–50 000 €</span>
                                </li>
                                <li className="flex items-start">
                                    <span className="mr-3 text-blue-600 text-lg">•</span>
                                    <span className="text-lg">Joustava takaisinmaksuaika</span>
                                </li>
                                <li className="flex items-start">
                                    <span className="mr-3 text-blue-600 text-lg">•</span>
                                    <span className="text-lg">Korko OP-prime + 6,95 %*</span>
                                </li>
                            </ul>
                            <ul className="space-y-4">
                                <li className="flex items-start">
                                    <span className="mr-3 text-blue-600 text-lg">•</span>
                                    <span className="text-lg">Laskutuspalkkio 7 €/kk</span>
                                </li>
                                <li className="flex items-start">
                                    <span className="mr-3 text-blue-600 text-lg">•</span>
                                    <span className="text-lg">Kuukausi korotonta ja kulutonta maksuaikaa</span>
                                </li>
                                <li className="flex items-start">
                                    <span className="mr-3 text-blue-600 text-lg">•</span>
                                    <span className="text-lg">Voit maksaa koko luoton kerralla pois ilman lisäkuluja</span>
                                </li>
                            </ul>
                        </div>

                        <div className="overflow-x-auto mb-8">
                            <table className="w-full border-collapse bg-white rounded-lg shadow-sm">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th className="px-4 py-3 border-b border-gray-200 text-gray-800 font-semibold">Määrä (€)</th>
                                        <th className="px-4 py-3 border-b border-gray-200 text-gray-800 font-semibold">5,000 €</th>
                                        <th className="px-4 py-3 border-b border-gray-200 text-gray-800 font-semibold">10,000 €</th>
                                        <th className="px-4 py-3 border-b border-gray-200 text-gray-800 font-semibold">15,000 €</th>
                                        <th className="px-4 py-3 border-b border-gray-200 text-gray-800 font-semibold">20,000 €</th>
                                        <th className="px-4 py-3 border-b border-gray-200 text-gray-800 font-semibold">25,000 €</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="hover:bg-gray-50">
                                        <th className="px-4 py-3 border-b border-gray-200 text-gray-800 font-semibold">6 v.</th>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">85</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">170</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">255</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">340</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">425</td>
                                    </tr>
                                    <tr className="hover:bg-gray-50">
                                        <th className="px-4 py-3 border-b border-gray-200 text-gray-800 font-semibold">5 v.</th>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">105</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">210</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">315</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">420</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">525</td>
                                    </tr>
                                    <tr className="hover:bg-gray-50">
                                        <th className="px-4 py-3 border-b border-gray-200 text-gray-800 font-semibold">4 v.</th>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">140</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">280</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">420</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">560</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">700</td>
                                    </tr>
                                    <tr className="hover:bg-gray-50">
                                        <th className="px-4 py-3 border-b border-gray-200 text-gray-800 font-semibold">3 v.</th>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">165</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">330</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">495</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">660</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">825</td>
                                    </tr>
                                    <tr className="hover:bg-gray-50">
                                        <th className="px-4 py-3 border-b border-gray-200 text-gray-800 font-semibold">2 v.</th>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">250</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">500</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">750</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">1000</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">1250</td>
                                    </tr>
                                    <tr className="hover:bg-gray-50">
                                        <th className="px-4 py-3 border-b border-gray-200 text-gray-800 font-semibold">1 v.</th>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">450</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">900</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">1350</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">1800</td>
                                        <td className="px-4 py-3 border-b border-gray-200 text-center">2250</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <p className="text-sm text-gray-600 mb-8 leading-relaxed bg-gray-50 p-6 rounded-xl">
                            Rahoitus on kertaluotto, jonka todellinen vuosikorko 7 500 euron luotolle on 12,56 %, kun luoton korko on OP-prime + 6,95 % (10,45 % 10/23), perustamismaksu 0 € ja laskutuspalkkio 7 €/kk. Arvioitu luoton kokonaiskustannus on 11 316,32 €. Laskelma on tehty olettaen, että luotto on nostettu kokonaan, luoton korko sekä maksut ja palkkiot pysyvät samana koko luottoajan ja luotto maksetaan takaisin 128 € minimilyhennyksin (1,70 % luoton määrästä) kuukauden välein, jolloin luottoaika on 89 kk. Luoton myöntää OP Yrityspankki Oyj, Gebhardinaukio 1, 00510 Helsinki.
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default LoanPage;