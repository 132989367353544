import React from 'react';
import "./Team.css";

const Team = () => {
    return (
        <section className="py-20 px-8 bg-white relative" style={{ backgroundColor: '#93c2fc' }}>
            <div className="max-w-7xl mx-auto">
                <h2 className="text-4xl font-bold text-center mb-12 text-white">Meidän Tiimimme</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                    <div className="text-center bg-white p-8 rounded-lg shadow-xl hover:shadow-2xl transition-all duration-500 ease-in-out transform hover:-translate-y-2 hover:scale-[1.02] relative overflow-hidden">
                        <div className="absolute inset-0 z-0 opacity-5">
                            <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
                                <defs>
                                    <pattern id="grid" width="10" height="10" patternUnits="userSpaceOnUse">
                                        <path d="M 10 0 L 0 0 0 10" fill="none" stroke="currentColor" strokeWidth="0.5" />
                                    </pattern>
                                </defs>
                                <rect width="100%" height="100%" fill="url(#grid)" />
                            </svg>
                        </div>
                        <div className="relative z-10">
                            <div className="relative mb-6 w-48 h-48 rounded-full mx-auto border-8 border-primary shadow-2xl drop-shadow-2xl overflow-hidden bg-black bg-opacity-20">
                                <img src="/images/arttu.png" alt="Tiimin jäsen 2" className="object-cover w-full h-full rounded-full transition-transform duration-300" />
                            </div>
                            <h3 className="text-5xl font-bold mb-5 text-primary fullname">Arttu Vanhatalo</h3>
                            <div className="space-y-6 sub-font">
                                <div className="bg-gradient-to-r from-blue-100 to-white p-5 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
                                    <a href="tel:+358453320162" className="group flex items-center justify-center space-x-4 text-blue-700 hover:text-blue-900">
                                        <div className="bg-blue-200 p-3.5 rounded-full group-hover:bg-blue-300 transition-colors">
                                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                                            </svg>
                                        </div>
                                        <span className="font-semibold text-lg">045 332 0162</span>
                                    </a>
                                </div>
                                <div className="bg-gradient-to-r from-blue-100 to-white p-5 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
                                    <a href="mailto:arttu.vanhatalo@nvgroup.fi" className="group flex items-center justify-center space-x-4 text-blue-700 hover:text-blue-900">
                                        <div className="bg-blue-200 p-3.5 rounded-full group-hover:bg-blue-300 transition-colors">
                                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                            </svg>
                                        </div>
                                        <span className="font-semibold text-lg">info@nvgroup.fi</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center bg-white p-8 rounded-lg shadow-xl hover:shadow-2xl transition-all duration-500 ease-in-out transform hover:-translate-y-2 hover:scale-[1.02] relative overflow-hidden">
                        <div className="absolute inset-0 z-0 opacity-5">
                            <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
                                <defs>
                                    <pattern id="grid2" width="10" height="10" patternUnits="userSpaceOnUse">
                                        <path d="M 10 0 L 0 0 0 10" fill="none" stroke="currentColor" strokeWidth="0.5" />
                                    </pattern>
                                </defs>
                                <rect width="100%" height="100%" fill="url(#grid2)" />
                            </svg>
                        </div>
                        <div className="relative z-10">
                            <div className="relative mb-6 w-48 h-48 rounded-full mx-auto border-8 border-primary shadow-2xl drop-shadow-2xl overflow-hidden bg-black bg-opacity-20">
                                <img src="/images/pasi.png" alt="Tiimin jäsen 2" className="object-cover w-full h-full rounded-full scale-110 transition-transform duration-300" />
                            </div>
                            <h3 className="text-5xl font-bold mb-5 text-primary fullname">Pasi Niskala</h3>
                            <div className="space-y-6 sub-font">
                                <div className="bg-gradient-to-r from-blue-100 to-white p-5 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
                                    <a href="tel:+358505387427" className="group flex items-center justify-center space-x-4 text-blue-700 hover:text-blue-900">
                                        <div className="bg-blue-200 p-3.5 rounded-full group-hover:bg-blue-300 transition-colors">
                                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                                            </svg>
                                        </div>
                                        <span className="font-semibold text-lg">050 538 7427</span>
                                    </a>
                                </div>
                                <div className="bg-gradient-to-r from-blue-100 to-white p-5 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
                                    <a href="mailto:pasi.niskala@nvgroup.fi" className="group flex items-center justify-center space-x-4 text-blue-700 hover:text-blue-900">
                                        <div className="bg-blue-200 p-3.5 rounded-full group-hover:bg-blue-300 transition-colors">
                                            <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                                                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                                            </svg>
                                        </div>
                                        <span className="font-semibold text-lg">info@nvgroup.fi</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Team;