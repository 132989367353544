import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
import Team from './components/Team';
import Contact from './components/Contact';
import { useRouter } from './context/RouterContext';
import GalleryPage from './pages/GalleryPage';
import RentCottagePage from './pages/RentCottagePage';
import LoanPage from './pages/LoanPage';
import OPCalculator from './components/OPCalculator';

function App() {
  const { currentPath } = useRouter();

  return (
    <>
      <div className="font-montserrat text-gray-800 m-0 p-0 leading-relaxed relative">
        <div className="absolute inset-0 z-[-1] overflow-hidden">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className="absolute bottom-0 w-full">
            <path fill="#93c5fd" fillOpacity="0.5" d="M0,96L48,112C96,128,192,160,288,160C384,160,480,128,576,122.7C672,117,768,139,864,149.3C960,160,1056,160,1152,138.7C1248,117,1344,75,1392,53.3L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
            <path fill="#60a5fa" fillOpacity="0.3" d="M0,192L48,181.3C96,171,192,149,288,154.7C384,160,480,192,576,197.3C672,203,768,181,864,165.3C960,149,1056,139,1152,144C1248,149,1344,171,1392,181.3L1440,192L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
          </svg>
        </div>
        <OPCalculator />
        <Header />
        {currentPath === "/" && (
          <>
            <Hero />
            <Services />
            <Team />
            <Contact />
          </>
        )}
        {currentPath === "/yhteydenotto" && (
          <div className='pt-12'>
            <Team />
            <Contact />
          </div>
        )}
        {currentPath === "/galleria" && (
          <>
            <GalleryPage />
            <Contact />
          </>
        )}
        {currentPath === "/vuokraus" && (
          <>
            <RentCottagePage />
            <Contact />
          </>
        )}
        {currentPath === "/rahoitus" && (
          <>
            <LoanPage />
            <Contact />
          </>
        )}
      </div>
    </>
  );
}

export default App;