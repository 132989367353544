import React from 'react';

const Contact = () => {
    return (
        <section className="py-20 px-8 bg-primary text-white text-center">
            <div className="max-w-2xl mx-auto">
                <h2 className="text-3xl font-bold mb-8">Ota yhteyttä</h2>
                <p className="mb-8">
                    Rakennamme ja remontoimme Etelä-Karjalan ja Kymenlaakson alueilla. Ota yhteyttä ja suunnitellaan remontti juuri sinun tarpeisiin.
                </p>
                <div className="flex flex-col items-center space-y-6">
                    <div className="flex items-center justify-center bg-secondary/20 rounded-full px-8 py-4 hover:bg-secondary/30 transition-all">
                        <a href="tel:0453320162" className="flex items-center text-white no-underline">
                            <div className="flex flex-col items-center">
                                <span className="text-2xl font-semibold">Arttu</span>
                                <div className="flex items-center">
                                    <i className="fa-solid fa-phone mr-2"></i>
                                    <span className="text-2xl font-bold">045 332 0162</span>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flex items-center justify-center bg-secondary/20 rounded-full px-8 py-4 hover:bg-secondary/30 transition-all">
                        <a href="tel:0505387427" className="flex items-center text-white no-underline">
                            <div className="flex flex-col items-center">
                                <span className="text-2xl font-semibold">Pasi</span>
                                <div className="flex items-center">
                                    <i className="fa-solid fa-phone mr-2"></i>
                                    <span className="text-2xl font-bold">050 538 7427</span>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="flex items-center justify-center bg-secondary/20 rounded-full px-8 py-4 hover:bg-secondary/30 transition-all">
                        <a href="mailto:info@nvgroup.fi" className="flex items-center text-white no-underline">
                            <div className="flex items-center">
                                <i className="fa-solid fa-envelope mr-2 text-xl"></i>
                                <span className="text-xl font-bold">info@nvgroup.fi</span>
                            </div>
                        </a>
                    </div>
                </div>
                <div className="flex justify-center gap-6 mt-8">
                    <a href="https://www.facebook.com/nvgroupoy" target="_blank" rel="noopener noreferrer" className="text-3xl hover:text-secondary transition-colors">
                        <i className="fa-brands fa-facebook"></i>
                    </a>
                    <a href="https://www.instagram.com/nvgroupoy" target="_blank" rel="noopener noreferrer" className="text-3xl hover:text-secondary transition-colors">
                        <i className="fa-brands fa-instagram"></i>
                    </a>
                </div>
                <div className="mt-8 text-sm opacity-75">
                    <p>Y-tunnus: 3315271-3</p>
                    <p>© {new Date().getFullYear()} NV-GROUP OY. Kaikki oikeudet pidätetään.</p>
                </div>
            </div>
        </section>
    );
};

export default Contact;